<template>
    <div>
        <el-form
            id="register-form"
            ref="model"
            label-position="top"
            @submit.native.prevent
            @keyup.enter.native="onSubmit"
        >
            <el-form-item>
                <p class="login-description text-center">
                    {{ message }}
                </p>
            </el-form-item>

            <el-form-item
                v-if="isMethodSelected"
                :error="errors()"
                :label="fieldLabel"
            >
                <el-input
                    id="backup_code"
                    v-model="code"
                    type="number"
                    name="backup_code"
                />
            </el-form-item>

            <el-form-item v-if="!isMethodSelected">
                <div class="flex-column flex">
                    <div>
                        <el-button
                            class="full-width padding"
                            type="primary"
                            :loading="isLoading"
                            @click="selectBackupCode"
                        >
                            Use backup code
                        </el-button>
                    </div>

                    <div v-if="hasBackupEmail">
                        <el-button
                            class="full-width padding"
                            type="primary"
                            :loading="isLoading"
                            @click="selectEmail"
                        >
                            Use backup email
                        </el-button>
                    </div>
                </div>
            </el-form-item>

            <el-form-item v-if="isMethodSelected">
                <el-button
                    class="full-width padding"
                    type="primary"
                    name="Login"
                    :loading="isLoading"
                    @click="onSubmit"
                >
                    Confirm
                </el-button>
            </el-form-item>

            <el-form-item>
                <a
                    v-if="!isMethodSelected"
                    :href="twofaUrl"
                    class="btn btn-link back-to-login-link"
                >
                    <i class="el-icon-arrow-left" /> Back
                </a>
                <el-button
                    v-if="isMethodSelected"
                    class="btn btn-link back-to-login-link"
                    type="text"
                    @click="resetMethod"
                >
                    <i class="el-icon-arrow-left" /> Cancel & use a different method
                </el-button>
            </el-form-item>

            <el-form-item v-if="false">
                <div class="register-now text-center">
                    Don't have a backup code?
                    <a :href="twofaUrl">
                        Use your authentication code
                    </a>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {routes, http, notify} from "Figured/Assets/Modules";

export default {
    template: "#twofa-backup",

    props: {
        hasBackupEmail: {
            type: Boolean,
            required: true,
        }
    },

    data() {
        return {
            isLoading: false,
            code: null,
            formErrors: {},
            validationMethod: null,
        }
    },

    computed: {
        twofaUrl() {
            return routes.get("two_factor_authentication.check.show")
        },


        isMethodSelected() {
            return this.validationMethod !== null;
        },

        fieldLabel() {
            switch (this.validationMethod) {
            case "backup_code":
                return "Backup Code";
            case "backup_email":
                return "Verification Code";
            default:
                return null;
            }
        },

        message(){
            switch (this.validationMethod) {
            case "backup_code":
                return "Enter one of the backup codes you received when you first set up Two-Factor Authentication";
            case "backup_email":
                return "We've sent an email to your backup email address with a verification code to enter here.";
            default:
                return "If you don't have access to your device, you can use either your backup codes or backup email.";
            }
        },

        route() {
            switch (this.validationMethod) {
            case "backup_code":
                return "two_factor_authentication.backup_check.check";
            case "backup_email":
                return "two_factor_authentication.check.post";
            }

            return "";
        },

        data() {
            switch (this.validationMethod) {
            case "backup_code":
                return {backup_code: this.code};
            case "backup_email":
                return {verification_method: "email", code_verification: this.code};
            }

            return {};
        }
    },

    methods: {
        resetMethod() {
            this.validationMethod = null;
            this.code = null;
            this.formErrors = [];
        },

        selectBackupCode() {
            this.validationMethod = "backup_code";
        },

        selectEmail() {
            this.isLoading = true;

            // Request verification code to be sent to the backup email
            http.get(routes.get("2fa.backup-code.show"))
                .then(() => {
                    this.validationMethod = "backup_email";
                })
                .catch(() => notify.error("Unable to send verification code to backup email."))
                .finally(() => {
                    this.isLoading = false;
                });
        },

        errors: function () {
            let inputName = this.validationMethod == "backup_code" ? "backup_code" : "code_verification";

            if (_.isUndefined(this.formErrors) || _.isUndefined(this.formErrors[inputName])) {
                return "";
            }

            return _.first(this.formErrors[inputName]);
        },

        onSubmit() {
            this.isLoading = true;

            http.post(routes.get(this.route), this.data)
                .then(response => {
                    if (response.status === 200) {
                        location.replace("/");
                        return;
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    // Redirect back if the user has entered the wrong code too many times
                    if (error.response.status === 429) {
                        location.replace(routes.get("two_factor_authentication.check.show"));
                    } else {
                        this.formErrors = error.response.data;
                        this.isLoading = false;
                    }
                });
        }
    },
}
</script>